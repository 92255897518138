@mixin triangle ($size, $color, $direction) {
  height: 0;
  width: 0;

  $width: nth($size, 1);
  $height: nth($size, length($size));

  $foreground-color: nth($color, 1);
  $background-color: transparent !default;
  @if (length($color) == 2) {
    $background-color: nth($color, 2);
  }

  @if ($direction == up) or ($direction == down) or ($direction == right) or ($direction == left) {

    $width: $width / 2;

    @if $direction == up {
      border-left: $width solid $background-color;
      border-right: $width solid $background-color;
      border-bottom: $height solid $foreground-color;

    } @else if $direction == right {
      border-top: $width solid $background-color;
      border-bottom: $width solid $background-color;
      border-left: $height solid $foreground-color;

    } @else if $direction == down {
      border-left: $width solid $background-color;
      border-right: $width solid $background-color;
      border-top: $height solid $foreground-color;

    } @else if $direction == left {
      border-top: $width solid $background-color;
      border-bottom: $width solid $background-color;
      border-right: $height solid $foreground-color;
    }
  }

  @else if ($direction == up-right) or ($direction == up-left) {
    border-top: $height solid $foreground-color;

    @if $direction == up-right {
      border-left:  $width solid $background-color;

    } @else if $direction == up-left {
      border-right: $width solid $background-color;
    }
  }

  @else if ($direction == down-right) or ($direction == down-left) {
    border-bottom: $height solid $foreground-color;

    @if $direction == down-right {
      border-left:  $width solid $background-color;

    } @else if $direction == down-left {
      border-right: $width solid $background-color;
    }
  }

  @else if ($direction == inset-up) {
    border-width: $height $width;
    border-style: solid;
    border-color: $background-color $background-color $foreground-color;
  }

  @else if ($direction == inset-down) {
    border-width: $height $width;
    border-style: solid;
    border-color: $foreground-color $background-color $background-color;
  }

  @else if ($direction == inset-right) {
    border-width: $width $height;
    border-style: solid;
    border-color: $background-color $background-color $background-color $foreground-color;
  }

  @else if ($direction == inset-left) {
    border-width: $width $height;
    border-style: solid;
    border-color: $background-color $foreground-color $background-color $background-color;
  }
}
.container-full {
  min-width: 100%;
  padding: 0;
}
// Here you can add other styles
.form-label {
  font-weight: 500 !important;
}
.table>tbody {
  vertical-align: middle !important;
}
.react-switch {
  vertical-align: middle;
  margin-left: 4px;
}
.modal-edit {
  label {
    margin-left: 5px;
    margin-top: 10px;
  }
}
.form-checkbox-custom {
  label {
    margin-left: 0 !important;
    margin-top: 0 !important;
  }
}
.div-date-delivery, .div-observations, .div-client-type {
  label {
    font-weight: bold;
  }
}
.form-control {
  fieldset {
    border-width: 0;
  }
  #role-select-modal, #client-select-modal, #worker-select-modal, #responsible-select-modal,
  #informant-select-modal, #delivery-select-modal, #client-type-select-modal, #family-select-modal,
  #responsible-stock-select-modal, #superfamily-select-modal, #supplier-select-modal {
    padding: 0;
  }
}
.orders-panel {
  h5 {
    margin-bottom: 0;
    cursor: not-allowed;
  }
}
.logo-app {
  width: 80%;
}
.simplebar-content {
  margin-top: 10px;
}
.header-nav .nav-link {
  color: #e74e00;
}
.header-nav-elements {
  margin-top: 8px;
}
.header-toggler {
  color: #e74e00;
  &:hover {
    color: #FEA100;
  }
}
.breadcrumb-item {
  a {
    color: #e74e00;
    &:hover {
      color: #FEA100;
    }
  }
}
.breadcrumb-item.active {
  color: #FEA100 !important;
}
.footer {
  a {
    color: #e74e00;
  }
}
.sidebar-nav .nav-link {
  color: rgb(255 255 255 / 90%);
}
.sidebar-nav .nav-link.active {
  color: #FEA100;
  svg {
    color: #FEA100 !important;
  }
}
.sidebar-nav .nav-title {
  font-size: 100%;
}
.btn-actions, .btn-group > .btn-primary, .input-group > .btn, .btn-edit-task, .btn-show-task {
  --bs-btn-color: #fff !important;
  --bs-btn-bg: #e74e00 !important;
  --bs-btn-border-color: #e74e00 !important;
  --bs-btn-hover-color: #fff !important;
  --bs-btn-hover-bg: #FEA100 !important;
  --bs-btn-hover-border-color: #FEA100 !important;
  --bs-btn-focus-shadow-rgb: 49,132,253 !important;
  --bs-btn-active-color: #fff !important;
  --bs-btn-active-bg: #FEA100 !important;
  --bs-btn-active-border-color: #FEA100 !important;
  --bs-btn-active-shadow: white !important;
  --bs-btn-disabled-color: #fff !important;
  --bs-btn-disabled-bg: #e74e00 !important;
  --bs-btn-disabled-border-color: #e74e00 !important;
}
.order-task {
  background: linear-gradient(90deg, rgb(162, 56, 5) 0%, rgb(203, 95, 40) 35%, rgb(238, 140, 90) 100%);
  color: white;
  border-radius: 5px !important;
  min-width: 300px;
  min-height: 57px;
}
.goal-task {
  background: linear-gradient(90deg, rgb(11, 52, 108) 0%, rgb(12, 61, 124) 35%, rgb(1, 73, 166) 100%);
  color: white;
  border-radius: 5px !important;
  min-width: 490px;
  min-height: 100px;
}
.btn-save-comments {
  background-color: #0065ff !important;
  color: white !important;
  border: 1px solid #0065ff !important;
  &:hover {
    background-color: white !important;
    color: #0065ff !important;
  }
}
.btn-add-comments {
  cursor: pointer;
}
.bg-without-stock {
  background: linear-gradient(90deg, rgb(86, 28, 0) 0%, rgb(119, 42, 0) 35%, rgb(138, 47, 0) 100%) !important;
  border: 1px solid red !important;
}
.bg-without-stock-business-order {
  background: linear-gradient(90deg, rgb(162, 56, 5) 0%, rgb(203, 95, 40) 35%, rgb(238, 140, 90) 100%);
  border: 3px solid red !important;
}
.bg-industry-order-without-stock {
  border: 3px solid red !important;
  background: linear-gradient(90deg, rgb(37, 99, 227) 0%, rgb(21, 39, 222) 35%, rgb(61, 116, 231) 100%) !important;
}
.bg-client-order {
  background: linear-gradient(90deg, rgb(90, 110, 1) 0%, rgb(106, 131, 0) 35%, rgb(130, 161, 0) 100%) !important;
}
.bg-client-order-without-stock {
  background: linear-gradient(90deg, rgb(31, 38, 0) 0%, rgb(49, 61, 0) 35%, rgb(56, 70, 0) 100%) !important;
  border: 1px solid red !important;
}
.bg-industry-order {
  background: linear-gradient(90deg, rgb(37, 99, 227) 0%, rgb(21, 39, 222) 35%, rgb(61, 116, 231) 100%) !important;
}
.bg-industry-business-order {
  background: linear-gradient(90deg, rgb(255, 129, 70) 0%, rgb(248, 105, 32) 35%, rgb(253, 126, 62) 100%);
}
.bg-with-stock-detail {
  background: linear-gradient(90deg, rgb(31, 145, 0) 0%, rgb(56, 199, 0) 35%, rgb(0, 169, 14) 100%) !important;
  color: white !important;
}
.bg-without-stock-detail {
  background: linear-gradient(90deg, rgb(154, 7, 0) 0%, rgb(199, 0, 0) 35%, rgb(169, 0, 0) 100%) !important;
  color: white !important;
}
.row-order-detail-info-user {
  background: lightgrey;
  border-radius: 10px;
  margin: 5px 25px;
}
.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #5e5757;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.btn-edit-task {
  float: left;
  display: inline-flex !important;
  padding: 5px !important;
  margin-top: 2px;
}
.btn-show-task {
  float: left;
  display: inline-flex !important;
  padding: 3px !important;
  margin-top: 2px;
}
.inputs-units-products {
  text-align: center;
  background-color: #f1f1f1;
  padding-bottom: 5px;
  input {
    text-align: center;
    width: 30%;
    margin: 0 auto;
    float: none;
  }
  label {
    justify-content: center;   /* center flex items vertically */
    align-items: center;
  }
}
.col-units-products-list {
  text-align: center;
  margin: 15px;
  input {
    text-align: center;
    width: 30%;
    margin: 0 auto;
    float: none;
  }
  label {
    justify-content: center;   /* center flex items vertically */
    align-items: center;
  }
}
.task-date-delivery {
  float: right;
  .content-task-date-delivery {
    font-size: x-small;
  }
}
.order-content {
  display: inline-flex;
  float: left;
  font-weight: bold;
  font-size: smaller;
  text-align: left;
  padding: 13px 5px 0 25px;
  vertical-align: -webkit-baseline-middle;
}
.goal-content {
  font-size: smaller;
  padding: 10px 20px 10px 20px;
  vertical-align: -webkit-baseline-middle;
  .goal-client {
    margin: 0;
    font-weight: bold;
  }
  .goal-content {
    margin: 0;
  }
}
.btn-actions, .btn-edit-task, .btn-show-task {
  font-weight: bold !important;
  &:hover {
    color: white !important;
    background-color: #FEA100 !important;
    border-color: #FEA100 !important;
  }
}
.row-observations-delivery {
  margin-top: 15px;
}
.row-products-units {
  padding-bottom: 20px;
  margin-top: 15px !important;
  display: flex;
}
.label-required {
  color: red;
}
.container-lg {
  max-width: 90% !important;
}
.container-lg:has(.orders-panel) {
  max-width: 100% !important;
}
.switch-obscure-values {
  position: relative !important;
  left: 0 !important;
  float: left;
}
.input-group .btn {
  z-index: 0 !important;
}
.product-units-label {
  border-bottom: 2px solid #c7c7c7;
  background-color: #c7c7c7;
}
.product-units-label-client {
  border-bottom: 2px solid #565452e8;
  background-color: #565452e8;
}
.product-units-info {
  border: 2px solid #c7c7c7;
  label {
    border-bottom: 1px solid #cccc;
  }
}
.product-stock-units {
  border-left: 2px solid #c7c7c7;
}
.title-delivered-stock-modal {
  font-size: large;
}
.label-product-units-name {
  margin-bottom: 0.5rem !important;
  margin-top: 0.5rem !important;
}
.color-white {
  color: white !important;
}
.color-red {
  color: red !important;
}
.color-green {
  color: green !important;
}
.background-color-green {
  background-color: green !important;
}
.background-color-red {
  background-color: red !important;
}
#profileImage {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: #512DA8;
  font-size: 21px;
  color: #fff;
  text-align: center;
  font-weight: 500;
}
#profileImageOrders {
  width: 31px;
  height: 31px;
  border-radius: 50%;
  background: #512DA8;
  font-size: 20px;
  color: #fff;
  text-align: center;
  cursor: default !important;
  font-weight: 500;
}
.row-user-configuration, .row-user-account {
  label {
    font-size: 22px;
  }
  .label-switch {
    font-size: 16px;
  }
}
.btn-actions-task-order {
  position: absolute !important;
  left: -10px !important;
  bottom: -8px !important;
  border-radius: 50% !important;
  background-color: #ffc107 !important;
  color: black !important;
  border-color: #ffc107 !important;
  &:hover {
    color: white !important;
    background-color: #FEA100 !important;
    border-color: #FEA100 !important;
  }
}
.btn-actions-task-order-close {
  position: absolute !important;
  left: -11px !important;
  bottom: -7px !important;
  border-radius: 50% !important;
  background-color: green !important;
  color: white !important;
  border-color: green !important;
  &:hover {
    background-color: #20ff00 !important;
    border-color: #20ff00 !important;
    color: #000000 !important;
  }
}
.btn-actions-task-industry-order-close {
  position: absolute !important;
  right: -5px !important;
  bottom: -7px !important;
  border-radius: 50% !important;
  background-color: green !important;
  color: white !important;
  border-color: green !important;
  padding: 3px !important;
  &:hover {
    background-color: #20ff00 !important;
    border-color: #20ff00 !important;
    color: #000000 !important;
  }
}
.btn-actions-task-order-show {
  position: absolute !important;
  left: -11px !important;
  bottom: -7px !important;
  border-radius: 50% !important;
  background-color: #5b5b5c !important;
  color: white !important;
  border-color: #5b5b5c !important;
  &:hover {
    background-color: #b4b4b4 !important;
    border-color: #b4b4b4 !important;
    color: black !important;
  }
}
.btn-actions-task-industry-order-show {
  position: absolute !important;
  left: -7px !important;
  bottom: -7px !important;
  border-radius: 50% !important;
  background-color: #444444 !important;
  color: white !important;
  border-color: #444444 !important;
  &:hover {
    background-color: #5b5b5c !important;
    border-color: #5b5b5c !important;
    color: #ffffff !important;
  }
}
.btn-actions-task-industry-business-order-show {
  position: absolute !important;
  left: -7px !important;
  bottom: -7px !important;
  border-radius: 50% !important;
  background-color: #444444 !important;
  color: white !important;
  border-color: #444444 !important;
  &:hover {
    background-color: #5b5b5c !important;
    border-color: #5b5b5c !important;
    color: #ffffff !important;
  }
}
.image-info-task-order {
  position: absolute !important;
  right: -8px !important;
  top: -11px !important;
  border-radius: 50% !important;
  .icon-lg {
    width: 1.45rem !important;
    height: 1.45rem !important;
  }
}
.urgent-icon-task {
  background-color: white !important;
  color: #032460 !important;
  cursor: auto !important;
  border-color: white !important;
  &:hover, &:active {
    background-color: white !important;
    color: #032460 !important;
    border-color: white !important;
  }
}
.delete-icon-task {
  background-color: white !important;
  color: red !important;
  cursor: auto !important;
  border-color: white !important;
  top: -8px !important;
  right: -5px !important;
  position: absolute;
  border-radius: 50% !important;
  &:hover, &:active {
    background-color: red !important;
    color: black !important;
    border-color: red !important;
  }
}
.date-icon-task {
  background-color: white !important;
  color: #032460 !important;
  cursor: auto !important;
  border-color: white !important;
  &:hover, &:active {
    background-color: white !important;
    color: #032460 !important;
    border-color: white !important;
  }
}
.image-responsible-delivery-task-order {
  position: absolute !important;
  right: -5px !important;
  bottom: -8px !important;
  border-radius: 50% !important;
  color: white !important;
}
.image-responsible-delivery-task-industry-order {
  position: absolute !important;
  right: -5px !important;
  bottom: -8px !important;
  border-radius: 50% !important;
  color: white !important;
  cursor: default!important;
  font-size: 18px;
  height: 30px;
  width: 30px;
}
.image-informant-with-first-credentials {
  position: absolute !important;
  left: -10px !important;
  top: -7px !important;
  border-radius: 50% !important;
  color: white !important;
  cursor: default!important;
  font-size: 18px;
  height: 30px;
  width: 30px;
}
.industry-order-created-at {
  position: absolute !important;
  left: -10px !important;
  top: -7px !important;
  border-radius: 50% !important;
}
.image-informant-task-order {
  position: absolute !important;
  left: -10px !important;
  top: -7px !important;
  border-radius: 50% !important;
  color: white !important;
}
.button-actions-list-orders {
  padding: 5px !important;
}
.dropdown-menu {
  a {
    text-decoration: none;
  }
}
.btn-create-order {
  width: 15%;
}
.box-filter-by {
  background: linear-gradient(90deg, rgb(145, 145, 145) 0%, rgb(65, 65, 65) 35%, rgb(145, 145, 145) 100%);
  margin: auto;
  padding: 10px;
  border: 2px solid white;
  h5 {
    color: white;
    margin: 0;
    display: inline;
  }
}
.box-tabs-order-list {
  display: inline-block;
  border-left: 1px solid white;
}
.box-tabs-order-list-dates {
  display: inline-block;
  border-left: 1px solid white;
}
.btn-tab-orders {
  font-size: 16px !important;
  padding-left: 10px;
  padding-right: 10px;
}
.tabs-orders-filter-dates {
  background: linear-gradient(90deg, rgb(154, 70, 5) 0%, rgb(178, 71, 5) 35%, rgb(220, 110, 0) 100%);
  .btn-tab-orders-dates {
    color: white;
  }
  .Mui-selected {
    color: white !important;
    border-top: 2px solid #ffffff;
    border-bottom: 2px solid #ffffff;
  }
}
.tabs-orders-list {
  background: linear-gradient(90deg, rgb(220, 110, 0) 0%, rgb(178, 71, 5) 35%, rgb(154, 70, 5) 100%);
  .btn-tab-orders-list {
    color: white;
  }
  .Mui-selected {
    color: white !important;
    border-top: 2px solid #ffffff;
    border-bottom: 2px solid #ffffff;
  }
}
.icon-list-orders-filter-dates {
  position: absolute;
  left: 1060px;
  top: 175px;
}
.icon-list-orders-filter-states{
  position: absolute;
  right: 800px;
  top: 175px;
}
.datatable-clients, .datatable-suppliers, .datatable-families, .datatable-superfamilies, .datatable-superfamilies, .datatables-contents {
  p {
    margin-bottom: 0 !important;
  }
}
.button-actions-datatables {
  margin: 1px;
}
.product-table {
  margin-top: 20px !important;
}
.actions-clients, .actions-suppliers, .actions-datatable {
  display: inline-flex;
}
.btn-dark {
  background-color: #5b5b5c;
}
.select-filter-type-task {
  align-items: center;
  display: inline-flex;
}
.select-filter-task {
  align-items: center;
  display: inline-flex;
  float: right;
}
.tasks-filter-select-modal {
  padding: 0;
  min-width: 450px;
}
.type-tasks-filter-select-modal {
  padding: 0;
  min-width: 300px;
}
.bg-blanchedalmond {
  background-color: blanchedalmond;
}
.bg-mintcream {
  background-color: mintcream;
}
.task-visits-container {
  position: relative;
  padding: 35px 15px 20px 15px !important;
  .btn-actions-task-visit-edit {
    position: absolute !important;
    right: 26px !important;
    top: -13px !important;
    border-radius: 50% !important;
    background-color: #ffc107 !important;
    color: black !important;
    width: 33px;
    border-color: #ffc107 !important;
    &:hover {
      color: white !important;
      background-color: #FEA100 !important;
      border-color: #FEA100 !important;
    }
  }
  .btn-actions-task-visit-delete {
    position: absolute !important;
    right: -13px !important;
    top: -13px !important;
    border-radius: 50% !important;
    background-color: #af0000 !important;
    color: white !important;
    width: 33px;
    border-color: #af0000 !important;
    &:hover {
      color: white !important;
      background-color: red !important;
      border-color: red !important;
    }
  }
  .div-task-date {
    width: 101%;
    height: 30px;
    background: linear-gradient(90deg, rgb(241, 138, 63) 0%, rgb(193, 77, 0) 35%, rgb(241, 138, 63) 100%);
    position: absolute;
    bottom: 9px;
    left: -6px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    &:before, &:after {
      content: "";
      position: absolute;
    }
    &:before {
      @include triangle(10px, #5e2500, up-right);
      bottom: -10px;
      left: 0;
    }
    &:after {
      @include triangle(10px, #5e2500, up-left);
      right: 0;
      bottom: -10px;
    }
    span {
      color: white;
    }
    .icon-arrow-dates {
      padding-top: 7px
    }
  }
  .div-note-date {
    background: linear-gradient(90deg, rgb(1, 180, 224) 0%, rgb(0, 94, 115) 35%, rgb(1, 180, 224) 100%);
    &:before {
      @include triangle(10px, #003d4f, up-right);
    }
    &:after {
      @include triangle(10px, #003d4f, up-left);
    }
  }
  .task-description {
    padding-bottom: 10px;
  }
  .div-image-responsible-task {
    position: absolute !important;
    left: 25px !important;
    top: -13px !important;
    display: flex;
    .cil-arrow-right-task-responsible {
      margin-top: 9px;
      color: #e74e00;
    }
    .image-responsible-task {
      border-radius: 50% !important;
      color: white !important;
      margin-left: 3px;
    }
  }
  .image-informant-task {
    position: absolute !important;
    left: -10px !important;
    top: -13px !important;
    border-radius: 50% !important;
    color: white !important;
  }
  .btn-actions-task-done {
    position: absolute !important;
    right: 65px !important;
    top: -13px !important;
    border-radius: 50% !important;
    background-color: green !important;
    color: white !important;
    border-color: green !important;
    &:hover {
      background-color: #20ff00 !important;
      border-color: #20ff00 !important;
      color: #000000 !important;
    }
  }
  .btn-actions-task-to-do {
    position: absolute !important;
    right: 65px !important;
    top: -13px !important;
    border-radius: 50% !important;
    background-color: #cc6d01 !important;
    color: white !important;
    border-color: #cc6d01 !important;
    &:hover {
      background-color: #ff6200 !important;
      border-color: #ff6200 !important;
      color: #000000 !important;
    }
  }
}
.date-time-picker {
  margin-top: 5px !important;
  display: block !important;
  vertical-align: middle !important;
  input {
    padding: 4px !important;
  }
}
.title-client-content-visit {
  color: #e74e00;
}
#responsible-select-modal, #client-select-modal {
  margin-bottom: 0.5rem;
}
.div-datetime-task {
  background-color: #f9f9f9;
  padding: 10px;
}
.col-datetime-task {
  position: relative;
  .icon {
    position: absolute;
    left: 0;
  }
}
.date-picker-dates {
  background: initial;
}
.rbc-event-label {
  display: none !important;
}
.bg-event {
  background-color: #e0ffe0;
}
.task-visits-container {
  .bg-event-date {
    background: linear-gradient(90deg, rgb(93, 224, 93) 0%, rgb(0, 140, 0) 35%, rgb(93, 224, 93) 100%);
    &:before {
      @include triangle(10px, #023f02, up-right);
    }
    &:after {
      @include triangle(10px, #023f02, up-left);
    }
  }
}
.rbc-time-view {
  margin-bottom: 30px;
}
.span-show-more-responsible-task {
  margin-top: 0.5rem !important;
  margin-left: 0.25rem;
  font-weight: bold;
  font-size: 20px;
  color: #e74e00;
}
.list-show-more-responsible {
  li {
    list-style: inside;
  }
}
.form-text {
  color: #2c2c2c;
  margin-bottom: 10px;
}
.modal-order-panel-informant-worker {
  font-size: 16px;
}
.text-open-modal-calendar {
  font-size: 15px;
  font-weight: 500;
}
.rbc-agenda-view .rbc-agenda-table .rbc-agenda-date-cell {
  background-color: #383838;
}
@keyframes doubleBounce {
  0%, 20%, 40%, 100% {
    transform: translateY(0);
    box-shadow: none;
    background-color: #d63031;
    color: white;
  }
  10%, 30% {
    transform: translateY(-3px);
    box-shadow: 0 0 10px #d63031;
    background-color: #e74c3c;
    color: #f1c40f;
  }
}
.nav-item-notifications {
  position: relative;
  transition: 0.5s;
  &:hover {
    .box-notifications{
      height: 370px;
    }
  }
  .number{
    height: 23px;
    width: 22px;
    background-color: #d63031;
    border-radius: 20px;
    color: white;
    text-align: center;
    position: absolute;
    top: -6px;
    left: 20px;
    padding: 3px;
    border-style: solid;
    border-width: 2px;
    font-size: 11px;
  }
  .has-notifications {
    animation: doubleBounce 1s infinite;
  }
  .number:empty {
    display: none;
  }
  .fas{
    font-size: 25pt;
    padding-bottom: 10px;
    color: black;
    margin-right: 40px;
    margin-left: 40px;
  }
  .box-notifications{
    width: 370px;
    height: 0;
    border-radius: 10px;
    transition: 0.5s;
    position: absolute;
    overflow-y: scroll;
    padding: 0;
    left: -250px;
    margin-top: 5px;
    background: linear-gradient(90deg, rgb(96, 95, 95) 0%, rgb(79, 79, 79) 35%, rgb(66, 66, 66) 100%);
    -webkit-box-shadow: 10px 10px 23px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 10px 10px 23px 0px rgba(0,0,0,0.1);
    box-shadow: 10px 10px 23px 0px rgba(0,0,0,0.1);
    cursor: context-menu;
  }
  .fas:hover {
    color: #d63031;
  }
  .content{
    padding: 20px;
    color: black;
    vertical-align: middle;
    text-align: left;
  }
  .gry{
    background-color: #F4F4F4;
  }
  .top{
    color: black;
    padding: 10px
  }
  .display{
    min-height: 200px;
    position: relative;
  }
  .cont{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .cont:empty{
    display: none;
  }
  .stick{
    text-align: center;
    display: block;
    font-size: 50pt;
    padding-top: 70px;
    padding-left: 80px
  }
  .stick:hover{
    color: black;
  }
  .cent{
    text-align: center;
    display: block;
    padding-top: 20px;
  }
  @keyframes colorChange {
    0% {
      color: #e74e00;
    }
    100% {
      color: #FEA100;
    }
  }
  .sec-header {
    transition: 0.5s;
    border-bottom: 1px solid white;
    padding: 9px 25px;
    font-weight: bold;
    animation: colorChange 1s infinite;
  }
  .sec{
    padding: 35px 25px 15px 25px;
    background: linear-gradient(90deg, rgb(96, 95, 95) 0%, rgb(79, 79, 79) 35%, rgb(66, 66, 66) 100%);
    transition: 0.5s;
    border-bottom: 1px solid white;
  }
  .bg-not-read-notification {
    background: linear-gradient(90deg, rgb(96, 95, 95) 0%, rgb(79, 79, 79) 35%, rgb(66, 66, 66) 100%);
  }
  .profCont{
    padding-left: 15px;
  }
  .profile{
    -webkit-clip-path: circle(50% at 50% 50%);
    clip-path: circle(50% at 50% 50%);
    width: 75px;
    float: left;
  }
  .txt{
    vertical-align: top;
    font-size: 18px;
    color: #ffb282;
  }
  .show-at-diff {
    color: #a7ffc3 !important;
  }
  .sub{
    font-size: 14px;
    color: white;
  }
  .new{
    border-style: none none solid none;
    position: relative;
  }
  .image-informant-nofitication {
    position: absolute;
    bottom: 5px;
    right: 5px;
  }
  #profileImageNotification {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #512DA8;
    font-size: 20px;
    color: #fff;
    text-align: center;
    cursor: default !important;
    font-weight: 500;
  }
  .box-notifications {
    a {
      text-decoration: none;
    }
  }
  .bg-not-read-notification {
    background-color: lightblue;
  }
  .btn-close-notification {
    position: absolute;
    top: 10px;
    right: 10px;
    color: white;
  }
  .pulsating-circle {
    position: absolute;
    top: 20px;
    left: 20px;
    transform: translateX(-50%) translateY(-50%);
    width: 18px;
    height: 18px;
    &:before {
      content: '';
      position: relative;
      display: block;
      width: 300%;
      height: 300%;
      box-sizing: border-box;
      margin-left: -100%;
      margin-top: -100%;
      border-radius: 45px;
      background-color: #cc6d01;
      animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
    }
    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      width: 100%;
      height: 100%;
      background-color: #e74e00;
      border-radius: 15px;
      box-shadow: 0 0 8px rgba(0,0,0,.3);
      animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite;
    }
  }
}
@keyframes pulse-ring {
  0% {
    transform: scale(.33);
  }
  80%, 100% {
    opacity: 0;
  }
}
@keyframes pulse-dot {
  0% {
    transform: scale(.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(.8);
  }
}
.rbc-calendar {
  .rbc-toolbar {
    button {
      background-color: #636161;
      color: white;
      &:hover {
        background-color: #7a7a7a !important;
        color: white !important;
      }
      &:active {
        background-color: #7a7a7a !important;
        color: white !important;
      }
      &:focus {
        color: white !important;
        background-color: #7a7a7a !important;
      }
    }
  }
  .rbc-toolbar {
    .rbc-toolbar-label {
      text-transform: uppercase;
      font-weight: bold;
      background: linear-gradient(90deg, rgb(241, 138, 63) 0%, rgb(193, 77, 0) 35%, rgb(241, 138, 63) 100%);
      color: white;
      padding: 3px;
      margin-left: 110px;
      margin-right: 110px;
      border-radius: 20px;
    }
  }
  .rbc-toolbar button.rbc-active {
    color: white !important;
    background-color: #e74e00 !important;
    border-bottom: 3px solid #FEA100 !important;
  }
  .rbc-today {
    background-color: white;
  }
}
.title-section-stats {
  border-bottom: 2px solid #FEA100 !important;
  margin-bottom: 15px !important;
}
.btn-add-focus-select-products {
  bottom: -5px;
  z-index: 1;
  right: 5px;
  position: absolute;
  border-radius: 100% !important;
}
.banner-order-shop {
  width: 100%;
  height: 355px;
}
.client-circuit-row {
  font-size: 13px;
  td {
    border-bottom-width: 0 !important;
  }
}
.circuit-row {
  border-top: none;
  font-size: 13px;
  td {
    border-bottom-width: 0 !important;
  }
}
.circuit-row-totals {
  border-top: none;
}
.searcher-orders-shop-mobile-container {
  background: white;
  position: sticky;
  width: 100%;
  right: 0;
  margin-bottom: 0 !important;
  top: 65px;
  z-index: 1;
  .searcher-orders-shop {
    position: sticky;
    z-index: 1;
    left: 0;
    top: 65px;
    height: 50px;
    border: 0 !important;
    border-bottom: 1px solid #d8dbe0 !important;
    border-radius: 0 !important;
    margin-top: 0;
    .search-button {
      background-color: #ffffff !important;
    }
    .icon-search {
      color: #e74e00;
    }
    .input-search {
      color: #e74e00;
      &::-webkit-input-placeholder, &:-moz-placeholder, &::-moz-placeholder, &:-ms-input-placeholder, &::placeholder {
        color: #e74e00 !important;
      }
    }
    input:focus-visible {
      outline: none;
    }
  }
}
.container-orders-shop {
  .inner-container-shop, .offers-container-shop {
    background: #ffffff;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    position: static;
    width: 100%;
    height: calc(100% - 30px);
    padding: 0;
    margin: 0;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    .infinite-scroll-component__outerdiv {
      -ms-flex: 1 0 280px;
      flex: 1 0 280px;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
      flex-flow: column nowrap;
      position: relative;
      overflow-x: hidden;
      background-color: inherit;
      margin: 15px auto 140px;
    }
    .product-container-box {
      display: -ms-inline-flexbox;
      display: inline-flex;
      float: none;
      margin-right: -4px;
      vertical-align: top;
      padding-left: 15px;
      padding-right: 5px;
      .product-box {
        box-shadow: 0 1rem 3rem rgb(239 239 239) !important;
        text-decoration: none;
        float: none;
        vertical-align: top;
        min-height: 1px;
        background: #FFFFFF;
        display: table;
        position: relative;
        width: 100%;
        word-break: break-word;
        border: 1px solid rgba(197, 199, 205, 0.3);
        margin-bottom: 30px;
        .favorite-button {
          background-color: transparent;
          border: none;
          position: absolute;
          top: 2px;
          right: 2px;
        }
        .add-favorite-button {
          svg {
            color: #464646;
            font-size: 17px;
          }
        }
        .remove-favorite-button {
          svg {
            color: #ff0000;
            font-size: 17px;
          }
        }
        .image-box {
          background-color: #efefef;
        }
        .div-product-family {
          font-size: 10px;
          background: linear-gradient(90deg, rgb(241, 138, 63) 0%, rgb(193, 77, 0) 35%, rgb(241, 138, 63) 100%);
          color: white;
          font-weight: bold;
          text-align: center;
          margin-bottom: 0 !important;
        }
        .product-info {
          padding: 10px;
          text-align: center;
          .product-name {
            font-size: 14px;
            color: #3d3b3af2;
            font-weight: 500;
          }
          .div-product-price {
            min-height: 60px;
            .product-price {
              padding-top: 5px;
              font-size: 15px;
              color: #df0000;
              margin: 0;
              position: absolute;
              bottom: 30px;
              left: 50%;
              transform: translate(-50%, -50%);
              min-width: 100%;
            }
            .product-text-without-price {
              font-size: 11px;
              left: 50%;
              bottom: 46px;
              transform: translateX(-50%);
              text-align: center;
              width: 100%;
            }
          }
          .div-product-price-without-price {
            min-height: 75px !important;
          }
        }
        .product-footer {
          .add-cart-button {
            background-color: #777777;
            border: none;
            font-size: 15px;
            position: absolute;
            bottom: 0;
            width: 100%;
            .cart-icon-shop {
              color: #ffffff;
              margin-right: 10px;
            }
            &:hover {
              color: white;
              background-color: #e74e00;
            }
          }
        }
        .pulsating-with-stock {
          position: absolute;
          top: 15px;
          left: 15px;
          transform: translateX(-50%) translateY(-50%);
          width: 12px;
          height: 12px;
          &:before {
            content: '';
            position: relative;
            display: block;
            width: 300%;
            height: 300%;
            box-sizing: border-box;
            margin-left: -100%;
            margin-top: -100%;
            border-radius: 45px;
            background-color: green;
            animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
          }
          &:after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            display: block;
            width: 100%;
            height: 100%;
            background-color: green;
            border-radius: 15px;
            box-shadow: 0 0 8px rgba(0,0,0,.3);
            animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite;
          }
        }
        .pulsating-without-stock {
          position: absolute;
          top: 15px;
          left: 15px;
          transform: translateX(-50%) translateY(-50%);
          width: 12px;
          height: 12px;
          &:before {
            content: '';
            position: relative;
            display: block;
            width: 300%;
            height: 300%;
            box-sizing: border-box;
            margin-left: -100%;
            margin-top: -100%;
            border-radius: 45px;
            background-color: #c40000;
            animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
          }
          &:after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            display: block;
            width: 100%;
            height: 100%;
            background-color: #c40000;
            border-radius: 15px;
            box-shadow: 0 0 8px rgba(0,0,0,.3);
            animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite;
          }
        }
      }
    }
    .div-filter-price {
      padding-top: 20px;
      .input-range__label-container {
        color: white !important;
      }
      .input-range__track--active {
        background: linear-gradient(90deg, rgb(241, 138, 63) 0%, rgb(193, 77, 0) 35%, rgb(241, 138, 63) 100%)
      }
      .input-range__slider {
        background: #ff5f00;
        border: 1px solid #ff5f00;
      }
    }
    .filters-order-shop {
      position: relative;
      margin-top: 15px;
      .searcher-aside-content {
        padding: 5px;
        width: 280px;
        position: -webkit-sticky;
        position: sticky;
        margin: 0;
        background: #565452e8;
        color: white;
        border-right: 1px solid #eee;
        overflow-y: auto !important;
        font-size: 14px;
        -webkit-text-size-adjust: 100%;
        top: 115px;
        height: 817px;
        &::-webkit-scrollbar {
          width: 5px;
          height: 5px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #dcdbdb;
          border-radius: 20px;
          border: 3px solid #dcdbdb;
        }
        &::-webkit-scrollbar-track {
          background: white;
        }
      }
      .filter_row {
        padding: 10px;
        width: 100%;
        .selected-order-by-filters {
          margin: 0;
          .css-1xc3v61-indicatorContainer, .css-15lsz6c-indicatorContainer {
            color: white !important;
            &:hover {
              color: white !important;
            }
          }
        }
        .checkbox-favorite-products {
          padding-left: 0;
          svg {
            color: #e74e00 !important;
          }
        }
      }
      .filter_row:last-child {
        border: none;
      }
      .categories-filter-aside {
        padding: 5px 0 5px 0;
        width: 280px;
        position: -webkit-sticky;
        position: sticky;
        margin: 0;
        color: white;
        overflow-y: auto !important;
        height: 518px;
        &::-webkit-scrollbar {
          width: 5px;
          height: 5px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #dcdbdb;
          border-radius: 20px;
          border: 3px solid #dcdbdb;
        }
        &::-webkit-scrollbar-track {
          background: white;
        }
        .row-category-item {
          margin: 10px 0;
          .checkbox-category-item {
            width: 20px;
            height: 20px;
            cursor: pointer;
            &:checked {
              position: relative;
              accent-color: #e74e00;
              &::before {
                accent-color: #e74e00;
              }
              &::after {
                accent-color: #e74e00;
              }
            }
          }
          .label-category-item {
            padding-left: 10px;
            padding-bottom: 0;
            font-size: 12px;
            font-weight: 400 !important;
            color: white;
            position: absolute;
            margin-top: 2px;
          }
        }
      }
      .filter_item {
        position: relative;
        .filter-title {
          border-bottom: 1px solid #E2E2E2;
          label {
            padding-bottom: 15px;
            font-size: 14px;
            font-weight: 500;
            color: white;
            text-transform: uppercase;
          }
        }
        .filter-title-total-hits {
          border-bottom: 1px solid #e74e00 !important;
          padding-bottom: 5px;
          span {
            font-size: 14px;
            font-weight: 500;
            font-style: italic;
          }
          .value-total-hits {
            font-size: 16px;
            font-weight: bold;
          }
        }
        .remove-filters-shop {
          position: absolute;
          font-weight: bold;
          top: -2px;
          cursor: pointer;
          background: #878787;
          padding: 4px;
          border-radius: 5px;
          right: 0;
        }
      }
    }
  }
}
#input-search-order-shop::placeholder {
  color: #e74e00;
  opacity: 0.6;
}
.icon-price-info {
  color: #004d9b;
  font-size: 17px;
  display: inline;
  margin-right: 3px;
}
.container-with-tabs, .container-stats, .container-bulk-actions, .container-modal {
  text-align: center;
  .box-tabs {
    width: 100%;
    margin-top: 15px;
    .btn-tab-orders-client {
      color: white;
      &:hover {
        background-color: #7a7a7a;
      }
    }
    .Mui-selected {
      background-color: #e74e00;
      border-bottom: 3px solid #FEA100;
      &:hover {
        background-color: #e74e00;
        border-bottom: 3px solid #FEA100;
      }
    }
  }
}
.container-stats {
  .show-totals-stats-section {
    background-color: #fff;
    border: 2px solid #ff6300;
    border-radius: 10px;
    color: #ff6300;
    font-weight: 700;
    padding: 0 20px;
    left: 0;
    margin: 20px 0;
    position: sticky;
    top: 114px;
    width: 100%;
    z-index: 1;
    .information-billing-top-bar {
      display: flex;
      justify-content: space-between;
      span {
        display: inline;
        font-size: 14px;
        margin: 0 10px;
        padding: 10px;
      }
    }
  }
  .recharts-container {
    margin-bottom: 40px;
  }
}
.modal-products-order-info-client {
  .modal-dialog {
    max-width: 500px !important;
  }
}
.select-client-create-order {
  z-index: 2;
}
.separator-right {
  color: #FEA100;
}
.modal-confirm-remove-product {
  .modal-content {
    background-color: #ededed !important;
    position: absolute;
    top: 70px;
  }
}
.section-billing {
  text-align: center;
  position: relative;
  .total-hits {
    text-align: left;
    font-weight: bold;
    padding-top: 10px;
    color: #645c5c;
  }
  .row-total-by-filters {
    background-color: #ffffff;
    color: #ff6300;
    font-weight: bold;
    border: 2px solid #ff6300;
    border-radius: 10px;
    left: 0;
    margin: 0 auto;
    position: sticky;
    top: 114px;
    z-index: 1;
    width: 100%;
    .information-billing-top-bar {
      display: flex;
      justify-content: space-between;
      .icon-arrow-dates-orders {
        width: 1rem;
        height: 1rem;
        font-size: 1rem;
      }
      span {
        padding: 10px;
        display: inline;
        margin: 0 10px;
        font-size: 14px;
      }
    }
  }
}
.select-billing-company {
  z-index: 2;
}
.container-stats-dashboard {
  margin: 20px 0 40px 0;
  .title-table-stats {
    text-align: right;
    margin-top: 10px;
    color: #e74e00;
  }
  .title-positive {
    color: #009138;
  }
  .title-negative {
    color: #980b03;
  }
  .caption-text-table-clients-percentage {
    text-align: center;
    font-size: 30px;
    padding: 0;
    font-weight: 500;
    color: #FEA100;
  }
}
.table-clients-percentage {
  width: 100%;
  table {
    width: 100%;
    display: table;
    text-align: center;
    thead {
      color: white;
      background: #333333;
    }
  }
  .positive-row {
    background-color: #007029;
    color: white;
  }
  .negative-row {
    background-color: #a10000;
    color: white;
  }
  .zero-row {
    background-color: #5b5b5c;
    color: white;
  }
}
.image-preview {
  width: 50% !important;
  height: 100% !important;
  margin: 10px;
}
.image-preview-icon {
  width: 100px !important;
  height: 100px !important;
  margin: 10px;
}
.image-datatable {
  img {
    max-height: 100px !important;
    margin: auto;
  }
}
.row-status-0 {
  background-color: #efcaca !important;
}
.row-status-1 {
  background-color: #beffb9 !important;
}
.btn-bulk-actions {
  text-align: right;
}
.separator {
  border-top: 3px solid #e74e00;
}
.separator-white {
  border-top: 2px solid white;
}
.container-tabs-actions {
  text-align: left;
}
.old-price-item {
  text-decoration: line-through;
  color: #565656;
  font-size: 14px;
  padding-left: 5px;
}
.discount-container {
  position: absolute;
  top: 140px;
  right: 5px;
  width: 45px;
  height: 45px;
  background-color: #ff0000;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
  font-size: 16px;
}
.container-products-offers-slider {
  .product-slider {
    width: 100%;
    padding: 0;
  }
  .product-slide {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    box-sizing: border-box;
  }
  .product-slide img {
    width: 100%;
    height: auto;
    max-width: 200px;
    object-fit: cover;
    margin-bottom: 10px;
  }
}
.container-orders-shop {
  .offers-container-shop {
    .product-container-box {
      background: white;
      .product-box {
        .product-info {
          .product-name {
            min-width: 279px;
            min-height: 48px;
          }
        }
      }
    }
  }
  .text-show-all-offers {
    font-weight: 500;
    font-size: 15px;
    color: red;
    text-decoration-line: underline;
    cursor: pointer;
  }
}
.title-section {
  color: #e74e00;
  font-weight: bold;
}
.card-view-order {
  text-align: center;
}
.image-info-details-order {
  font-size: 13px;
  position: absolute;
  top: -1px;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 4px !important;
  border-radius: 10px;
  background: #5b5b5c;
  color: white !important;
  border: 1px solid white !important;
  &:hover {
    background: #5b5b5c;
    color: white !important;
    border: 1px solid white !important;
  }
}
.image-info-details-business-order {
  font-size: 13px;
  position: absolute;
  top: -1px;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 4px !important;
  border-radius: 10px;
  background: #5b5b5c !important;
  color: white !important;
  border: 1px solid white !important;
  &:hover {
    background: #5b5b5c !important;
    color: white !important;
    border: 1px solid white !important;
  }
}
.div-datatable-discounts-clients {
  margin-top: 40px !important;
}
.datatable-products {
  .thead {
    font-size: 14px;
  }
}
.title-industry-orders {
  background: linear-gradient(90deg, rgb(0, 28, 94) 0%, rgb(0, 5, 45) 35%, rgb(0, 31, 101) 100%) !important;
  color: white;
  padding: 5px;
  border-radius: 10px;
  width: 50%;
  margin: auto;
  font-size: 20px;
}
.button-container-center {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.client-groups-container {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: flex-start;
}
.client-group {
  position: relative;
  flex: 0 0 calc(50% - 1rem);
}
.group-checkbox {
  display: none;
}
.group-label {
  background-color: #FFFFFF;
  color: black;
  border-radius: 0 20px 20px 0;
  padding-left: 20px;
  cursor: pointer;
  transition: transform 0.2s ease;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  border: 1px solid #bfbfbf;
}
.bg-darkred {
  background-color: red !important;
}
.bg-darkgreen {
  background-color: green !important;
}
.units-without-stock {
  background-color: #ffd5d5;
}
.units-with-stock {
  background-color: #e5ffd5;
}
.stock-units-info {
  font-size: 11px;
  font-weight: bold;
}
.btn-active-product {
  border-radius: 50% !important;
  background-color: green !important;
  color: white !important;
  border-color: green !important;
  margin-right: 10px;
  float: none;
}
.btn-deactivate {
  background-color: red !important;
  border-color: red !important;
}
.group-count {
  color: white;
  background-color: #e74e00;
  padding: 10px 15px;
  border-radius: 0 20px 20px 0;
  border-left: 2px solid white;
  align-self: stretch;
  min-width: 60px;
  text-align: center;
}
.group-count::before {
  content: '';
  display: block;
  position: absolute;
  left: -25px;
  top: 50%;
  transform: translateY(-50%);
  height: 24px;
  width: 24px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.group-checkbox:checked + .group-label svg {
  width: 16px;
  height: 16px;
  margin-right: 5px;
  vertical-align: middle;
}
.group-checkbox:checked + .group-label {
  transform: scale(1.02);
  background-color: #e74e00;
  color: white;
}
.group-name {
  display: flex;
  align-items: center;
}
.text-editor-container {
  background-color: white;
}
.title-section {
  display: flex;
  align-items: center;
  letter-spacing: 1px;
}
.subtitle-section {
  font-size: 0.8rem;
  color: white;
  font-weight: 600;
  margin-top: 25px;
  margin-bottom: 25px;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;
  background-color: #636161;
  border-radius: 20px;
  padding: 10px;
  width: 50%;
  left: 25%;
}
.color-black {
  color: black;
}
.number-bg {
  background-color: #636161;
  border-radius: 50%;
  padding: 5px 10px;
  color: white;
  margin-right: 10px;
}
.add-visit-btn {
  background-color: green;
  color: white;
  border-color: green;
  &:hover {
    background-color: #00b700;
    color: white;
    border-color: #00b700;
  }
}
.visit-history-btn {
  background-color: #525252;
  color: white;
  border-color: #525252;
  &:hover {
    background-color: #818181;
    color: white;
    border-color: #818181;
  }
}
.goals-btn {
  background-color: mediumblue;
  color: white;
  border-color: mediumblue;
  &:hover {
    background-color: #2525ff;
    color: white;
    border-color: #2525ff;
  }
}
.stats-btn {
  background-color: #e74e00;
  color: white;
  border-color: #e74e00;
  &:hover {
    background-color: #f86118;
    color: white;
    border-color: #f86118;
  }
}
.row-add-comment {
  display: flex;
}
.element-hidden {
  display: none !important;
}
.left-input-image-with-credentials {
  background-color: rgb(255, 0, 4);
  position: relative !important;
  left: inherit !important;
  right: inherit !important;
  top: inherit !important;
  bottom: inherit !important;
  text-align: center;
  margin-right: 10px;
}
.row-show-comments {
  display: flex;
  align-items: center;
  .date-goal-comment {
    font-weight: 400 !important;
    font-size: 14px !important;
  }
}
.table-top {
  .padding-left-72 {
    padding-left: 72px !important;
  }
  .position-cell {
    position: relative;
    padding-left: 5px !important;
    .trophy-icon {
      width: 35px;
      margin: auto;
    }
    .position {
      border-radius: 50%;
      background-color: #111;
      border: 2px solid #fff;
      box-shadow: 0 0 4px #999;
      padding: 2px 0 0;
      color: #fff;
      width: 30px;
      height: 30px;
      display: block;
      text-align: center;
      line-height: 23px;
      font-weight: 600;
      font-size: 16px;
      margin: auto;
    }
  }
  .cell-item-total {
    padding-right: 5px !important;
  }
  .item-total {
    color: white !important;
    background-color: #e74e00;
    border: 1px solid #fff;
    box-shadow: 0 0 4px #FEA100;
    padding: 3px 0 0 !important;
    width: 30px;
    height: 30px;
    display: block;
    text-align: center;
  }
}
.color-brand {
  color: #e74e00;
}
.content-notifications {
  white-space: pre-wrap;
}
.save-product-variation {
  margin-top: 45px;
  svg {
    color: white;
  }
}
.table-variations-product td {
  padding: 4px 8px;
  font-size: 12px;
  height: 30px;
}
.text-heading {
  color: #FEA100;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 16px;
}
.icon-yes {
  color: green;
}
.icon-no {
  color: red;
}
.autologin-client-manager {
  background-color: #e74e00 !important;
  color: white !important;
  border-color: #e74e00 !important;
}
.image-thumbnails, .selected-images-preview {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}
.thumbnail-image, .preview-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 4px;
  border: 1px solid #ddd;
  cursor: pointer;
}
.font-weight-normal {
  font-weight: normal !important;
}
/*** END STYLES DESKTOP ***/
/*** SPINNER ***/
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  animation: spinner 1.5s linear infinite;
}
.spinner-container {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 550px;
}
/*** END SPINNER ***/

/*** ONLY LG STYLES ***/
@media(min-width: 1024px) {
  .modal-footer-informant-worker {
    margin-right: 130px;
  }
}
/*** END ONLY LG STYLES ***/

/*** RESPONSIVE STYLES ***/
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 70% !important;
  }
}
@media (min-width: 992px) {
  .modal-lg, .modal-xl {
    --bs-modal-width: 90% !important;
    max-width: 90% !important;
  }
  .div-client-task {
    margin-bottom: 1rem;
  }
  .date-time-picker-dates {
    display: inline !important;
  }
  .col-dates-range-task {
    .icon {
      margin: 20px 5px 0 5px;
    }
  }
  .date-picker-dates {
    display: inline !important;
    width: 25% !important;
  }
}
@media(max-width: 900px) {
  .nav-item-orders-panel {
    display: none;
  }
  .responsive-hidden {
    display: none !important;
  }
}
@media(max-width: 1200px) {
  .task-container-column {
    margin: 2px !important;
    width: 195px !important;
  }
  .order-task {
    min-width: 190px !important;
    max-width: 190px !important;
  }
  .goal-task {
    min-width: 190px !important;
    max-width: 190px !important;
  }
  .order-columns {
    padding: 2px !important;
  }
  .image-express {
    display: none;
  }
  .task-date-delivery {
    display: none;
  }
  .header-brand {
    width: 25%;
  }
  .order-content {
    padding: 7px 5px 0 23px;
  }
}
@media (min-width: 768px) {
  .responsive-hidden-desktop {
    display: none !important;
  }
  .filters-order-shop {
    max-height: 85%
  }
  .image-box {
    object-fit: contain !important;
    background-color: #efefef;
  }
}
/*** MOBILE STYLES ***/
@media(max-width: 767px) {
  .welcome-user {
    display: none;
  }
  .login-card-register {
    width: 100% !important;
  }
  .inputs-units-products {
    input {
      width: 100%;
    }
  }
  .btn-create-order {
    width: 35%;
  }
  .div-actions-create-order {
    text-align: center;
  }
  .tabs-orders-list .tab-orders-list {
    font-size: 15px !important;
    padding-left: 0;
    padding-right: 0;
  }
  .table > :not(caption) > * > * {
    padding: 5px 0 5px 0 !important;
  }
  .task-container-column {
    margin: 2px !important;
    width: 180px !important;
  }
  .order-task {
    min-width: 170px !important;
    max-width: 170px !important;
  }
  .goal-task {
    min-width: 170px !important;
    max-width: 170px !important;
  }
  .product-stock-units {
    border-left: 0 !important;
    border-top: 1px solid #c7c7c7;
  }
  .row-units-uroducts-list-orders {
    margin: 0 !important;
  }
  .product-list-orders-info {
    padding-top: 5px;
  }
  .col-units-products-list {
    margin: 0;
  }
  .orders-panel-overflow {
    overflow: scroll;
  }
  .task-container-column {
    margin: 2px !important;
    width: 175px !important;
    border: 0 !important;
  }
  .image-informant-task-order {
    left: -5px !important;
  }
  .btn-actions-task-order {
    left: -5px !important;
  }
  .order-content {
    padding: 10px 5px 0 15px;
    text-align: center;
    float: none;
    display: inline-grid;
  }
  .image-info-task-order {
    right: -4px !important;
    top: -10px !important;
  }
  .btn-actions-task-industry-order-show, .btn-actions-task-industry-business-order-show {
    left: -5px !important;
  }
  .image-responsible-delivery-task-order, .image-responsible-delivery-task-industry-order {
    right: -3px !important;
    bottom: -9px !important;
  }
  .btn-edit-task {
    padding: 3px !important;
  }
  #profileImageOrders {
    width: 29px;
    height: 29px;
    font-size: 18px;
  }
  .box-filter-by {
    width: 100% !important;
  }
  .box-tabs-order-list {
    width: 50% !important;
  }
  .box-tabs-order-list-dates {
    width: 50% !important;
  }
  .btn-tab-orders {
    font-size: 15px !important;
  }
  .icon-list-orders-filter-dates {
    left: 115px;
    top: 183px;
  }
  .icon-list-orders-filter-states {
    right: 115px;
    top: 183px;
  }
  .actions-clients, .actions-suppliers {
    display: initial;
  }
  .container-lg {
    max-width: 100% !important;
  }
  .btn-actions-task-order-close {
    left: -5px !important;
  }
  .btn-actions-task-order-show {
    left: -5px !important;
  }
  .switch-obscure-values {
    float: none;
  }
  .div-filter-task-content-type {
    text-align: center;
  }
  .div-filter-task-client {
    text-align: center;
  }
  .tasks-filter-select-modal {
    min-width: 300px;
  }
  .type-tasks-filter-select-modal {
    min-width: 300px;
  }
  .select-filter-task {
    float: none;
  }
  .task-visits-container {
    .btn-actions-task-visit-edit {
      width: 9%;
      right: 25px !important;
    }
    .btn-actions-task-visit-delete {
      width: 9%;
      right: -10px !important;
    }
    .div-task-date {
      width: 105%;
    }
    .div-image-responsible-task {
      left: 22px !important;
    }
    .btn-actions-task-done {
      right: 60px !important;
    }
  }
  .date-time-picker-dates {
    padding: 5px !important;
  }
  .task-visits-container .div-task-date span {
    font-size: 11px;
  }
  .rbc-calendar {
    text-align: center;
    .rbc-toolbar {
      display: block;
    }
    .rbc-toolbar-label {
      display: block;
      font-weight: 700;
      margin: 5px;
    }
  }
  .footer {
    text-align: center !important;
  }
  .btn-tab-contents-tasks {
    padding: 0;
    font-size: 13px !important;
  }
  .nav-item-notifications {
    .box-notifications {
      margin-top: 21px;
      width: 300px;
      left: -177px;
    }
  }
  .nav-item-notifications {
    .display {
      min-height: 220px;
    }
  }
  .searcher-orders-shop-mobile-container {
    padding-top: 7px;
    border-radius: 5px !important;
    .searcher-orders-shop {
      width: 100%;
      right: 0;
      margin-bottom: 0 !important;
      background-color: #efefef;
      border-radius: 5px !important;
      border: 1px solid #dddddd !important;
      top: 73px;
      .search-button {
        background-color: transparent !important;
        padding: 0 10px !important;
      }
      .input-search {
        background-color: #efefef;
        color: #3d3b3af2;
      }
      .icon-search {
        color: #3d3b3af2
      }
      #input-search-order-shop::placeholder {
        color: #3d3b3af2;
      }
    }
  }
  .container-orders-shop {
    .inner-container-shop, .offers-container-shop {
      .product-container-box {
        margin: 0;
        padding: 0 5px;
        max-width: 50%;
      }
    }
  }
  .order-by-shop {
    border: 0 !important;
    margin: 0 !important;
    .row-order-by-filters-mobile {
      width: 100%;
      padding: 15px 5px;
      display: flex;
      .filter-title-total-hits {
        span {
          font-size: 14px;
          font-weight: 500;
          font-style: italic;
        }
        .value-total-hits {
          font-size: 16px;
          font-weight: bold;
        }
        padding-left: 25px
      }
      .selected-order-by-filters {
        position: fixed;
        right: 20px;
        min-width: 180px;
        top: 131px;
        margin-bottom: 0 !important;
      }
    }
  }
  .add-cart-button:hover {
    color: white !important;
    background-color: #777777 !important;
  }
  .sticky-btn-filters-mobile {
    position: fixed;
    background: linear-gradient(90deg, rgb(220, 110, 0) 0%, rgb(178, 71, 5) 35%, rgb(220, 110, 0) 100%);
    bottom: 35px;
    left: 45%;
    width: 45px;
    height: 45px;
    svg {
      color: #efefef;
      width: 2rem !important;
      height: 2rem !important;
    }
  }
  .container-orders-shop {
    .inner-container-shop, .offers-container-shop {
      .filters-order-shop {
        position: absolute;
        z-index: 1;
        left: 0;
        width: 100%;
        .filter_row {
          padding: 2px 10px;
          .selected-order-by-filters {
            width: 80%;
            margin-top: 35px;
          }
        }
        .searcher-aside-content {
          height: 700px;
          width: 100%;
          position: fixed;
          top: 176px;
          left: -330px;
          transition: -webkit-transform 2s ease;
          transition: transform 2s ease;
          -webkit-transform: translateX(-110%);
          -ms-transform: translateX(-110%);
          transform: translateX(-110%);
        }
        .searcher-aside-content-open {
          transition: -webkit-transform 2s ease;
          transition: transform 2s ease;
          -webkit-transform: translateX(330px);
          -ms-transform: translateX(330px);
          transform: translateX(330px);
          background: #181818e8;
        }
        .categories-filter-aside {
          width: 100%;
          height: 495px;
          margin-top: 10px;
        }
        .list-categories-filters {
          margin-top: 10px;
        }
        .filter_item {
          .filter-title {
            border-bottom: none;
            label {
              padding-bottom: 0;
            }
          }
        }
        .btn-close-filters-mobile {
          position: fixed;
          top: 10px;
          right: 10px;
        }
        .remove-filters-shop-mobile {
          position: absolute;
          left: 4%;
          font-weight: bold;
          top: 10px;
          cursor: pointer;
          font-size: 13px;
          background: #878787;
          padding: 4px;
          border-radius: 5px;
        }
      }
    }
  }
  .rbc-calendar {
    .rbc-toolbar .rbc-toolbar-label {
      margin-left: 10px;
      margin-right: 10px;
    }
    margin-bottom: 10px;
  }
  .global-filters {
    margin-top: 10px;
  }
  .section-billing .total-hits {
    text-align: center;
  }
  .container-with-tabs, .container-stats, .container-bulk-actions, .container-modal {
    .box-tabs {
      .btn-tab-orders-client {
        font-size: 12px;
      }
    }
    .tab-order-list {
      font-size: 11px !important;
    }
    .show-totals-stats-section {
      padding: 3px;
      top: 118px;
      .information-billing-top-bar {
        display: grid;
        justify-content: center;
        span {
          padding: 0;
        }
      }
    }
  }
  .row-order-detail-info-user {
    margin: 5px 0;
  }
  .td-promotional-code {
    display: inline-grid;
  }
  .title-industry-orders {
    width: 100%;
  }
  .actions-clients, .actions-suppliers, .actions-datatable {
    display: block;
  }
  .table-top {
    .product-header {
      padding-left: 30px !important;
    }
  }
  .title-table-stats {
    font-size: 1rem !important;
  }
}
/*** END MOBILE STYLES ***/
